@banner3: banner3;
.@{banner3} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://www.chengwenit.com/img/bj3.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  & &-text-wrapper {
    color: @template-text-color-light;
    max-width: 444px;
    width: 80%;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    >.queue-anim-leaving {
      position: relative !important;
    }
  }
  .hot-box {
    max-width: 1200px;
    position: relative;
    height: 55px;
    margin: 0 auto;
    margin-top: 20px;
    .hot-content {
      width: 700px;
      text-align: center;
      position: absolute;
      right: -20px;
      bottom: 0px;
    }
    .hot-title {
      font-size: 30px;
      color: #fff;
      margin-right: 20px;
      font-weight: 600;
      display: inline-block;
      vertical-align: middle;
    }
    button {
      width: 145px;
      height: 50px;
      background-color: #fff;
      color: #7a6df8;
      border-radius: 10px;
      border: 0;
    }
  }
  .queue-admin {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
  }
  & &-card-wrapper {
    display: flex;
    width: 700px;
    border-radius: 30px;
    background-color: #fff;
    flex-wrap: wrap;
    padding: 15px;
    gap: 30px;
    transform: translateX(70px);
    .card-content {
      flex: 1;
      text-align: left;
      cursor: pointer;
      img {
        width: 300px;
        height: 200px;
      }
      .card-pic {
        border-radius: 10px;
      }
      .card-tag {
        display: inline-block;
        border-radius: 5px;
        padding: 0 5px;
        margin-top: 20px;
        font-weight: 600;
      }
      .card-title {
        margin-top: 10px;
      }
    }
  }
  & &-slogan {
    font-size: 68px;
    line-height: 80px;
    text-indent: 2px;
    font-weight: 600;
    margin: 26px auto 38px;
    overflow: hidden;
  }
  & &-name-en {
    display: block;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
  }
  & &-name {
    font-size: 20px;
    overflow: hidden;
    // opacity: 0.8;
    font-weight: 600;
    text-align: left;
  }
  & &-button {
    display: block;
    margin: 72px auto 0;
    background: rgb(3, 67, 101);
    background: -moz-linear-gradient(left, rgba(3, 67, 101, 1) 0%, rgba(0, 27, 51, 1) 100%);
    background: linear-gradient(to right, rgba(3, 67, 101, 1) 0%, rgba(0, 27, 51, 1) 100%);
    box-shadow: 0 8px 16px #0a52ab;
    border: none;
    transition: background .45s @ease-out;
    width: 132px;
    line-height: 35px;
    height: 42px;
    border-radius: 42px;
    a {
      color: green;
    }
  }
  & &-time {
    font-size: 14px;
    line-height: 24px;
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .@{banner3} {
    height: 110rem;
    background-attachment: inherit;
    & &-text-wrapper {
      width: 90%;
      height: 50%;
      // padding: 0 5%;
      margin: 0 auto;
    }
    & &-name-en {
      font-size: 12px;
    }
    & &-slogan {
      font-size: 24px;
      line-height: 1.5;
      margin: 12px 0;
    }
    & &-name {
      font-size: 14px;
    }
    & .queue-admin {
      display: inherit;
    }
    & &-card-wrapper {
      width: 90%;
      transform: translateX(0px);
      margin: 0 auto;
      margin-top: 25px;
    }
    .hot-box {
      width: 100%;
      .hot-content {
        width: 100%;
        right: 0px;
      }
      .hot-title {
        font-size: 18px;
      }
    }
  }
}
